<template>
	<ShowTicketModal :show="showTicket" @close="showTicket = false" />
	<div class="layout-padding my-8">
		<div class="grid grid-cols-2 gap-x-10 md:row-start-2 mt-12" v-if="workshop">
			<div class="col-span-2 lg:col-span-1 order-last lg:order-first">
				<div class="section-title lora-bold">{{ content.ourWorkshops }}</div>
				<div class="breadcrumb mt-3">
					<router-link to="/">{{ content.home }}</router-link> /
					<router-link to="/workshops"> {{ content.workshops }}</router-link> /
					{{ content.ourWorkshops }}
				</div>
				<div class="about-content mt-12">
					{{ workshop.description }}
				</div>
			</div>
			<div
				class="col-span-2 lg:col-span-1 order-first lg:order-last mb-12 lg:mb-0"
			>
				<div class="relative">
					<swiper
						:slides-per-view="1"
						:space-between="20"
						:effect="'fade'"
						:pagination="pagination"
						:loop="true"
						:autoplay="autoplay"
					>
						<swiper-slide v-for="(image, index) in images" :key="index">
							<div
								:style="`background: url(${image})`"
								class="workshop-image"
							/>
						</swiper-slide>
					</swiper>
					<div class="workshops-pagination"></div>
				</div>
			</div>
		</div>
		<div class="lora-bold text-size-20 mt-12 mb-8" v-if="workshops.length > 0">
			{{ content.upcomingWorkshops }}
		</div>
		<div class="grid grid-cols-2 gap-5" v-if="workshops.length > 0">
			<div
				class="col-span-2 lg:col-span-1"
				v-for="workshop in workshops"
				:key="workshop.id"
			>
				<UpcomingWorkshopCard :workshop="workshop" />
			</div>
		</div>
	</div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, {
	Navigation,
	Pagination,
	EffectFade,
	Autoplay,
} from 'swiper';
import UpcomingWorkshopCard from '/src/components/ui/Workshops/UpcomingWorkshopCard.vue';

import 'swiper/swiper-bundle.css';
import 'swiper/components/navigation/navigation.min.css';
import ShowTicketModal from '../../components/ui/Workshops/ShowTicketModal.vue';

SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);

export default {
	components: {
		Swiper,
		SwiperSlide,
		UpcomingWorkshopCard,
		ShowTicketModal,
	},
	inject: ['content'],
	data() {
		return {
			showTicket: false,
			navigation: {
				nextEl: '#nextWorkshop',
				prevEl: '#prevWorkshop',
			},
			autoplay: {
				delay: 2000,
			},

			breakpoints: {
				// when window width is >= 320px
				0: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				// when window width is >= 480px
				567: {
					slidesPerView: 2,
					spaceBetween: 20,
				},
				// when window width is >= 640px
				992: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
				1200: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
				1500: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
				1800: {
					slidesPerView: 4,
					spaceBetween: 20,
				},
			},
			pagination: {
				el: '.workshops-pagination',
				type: 'bullets',
				clickable: true,
				renderBullet: function () {
					return `<span class="dot swiper-pagination-bullet" style="background: #fff !important; margin-inline-start:10px;width:10px;height:3px;margin-top:30px" ></span>`;
				},
			},
		};
	},
	mounted() {
		this.$store.dispatch('workshops/workshops');
	},
	computed: {
		workshops() {
			return this.$store.getters['workshops/workshops'];
		},
		workshop() {
			return this.$store.getters['pages/text']('workshop');
		},
		images() {
			let images = [];

			if (this.workshop?.image) images.push(this.workshop?.image);

			if (this.workshop?.images) {
				for (let i = 0; i < this.workshop?.images?.length; i++) {
					images.push(this.workshop?.images[i].url);
				}
			}

			return images;
		},
	},
};
</script>

<style>
.workshops-pagination {
	position: absolute;
	bottom: 30px;
	left: 0vw;
	width: 100%;
	display: flex;
	justify-content: center;
	z-index: 2;
}
.workshops-pagination .swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
	padding: 20px 4vw;
	display: flex;
}
.workshops-pagination .swiper-pagination-bullet {
	border-radius: 0;
	height: 3px;
	width: 60px !important;
	background: #302b87 !important;
	opacity: 0.5 !important;
}
.workshops-pagination .swiper-pagination-bullet-active {
	background: #302b87;
	opacity: 1 !important;
}
@media (max-width: 567px) {
	.workshops-pagination .swiper-pagination-bullet {
		width: 30px !important;
	}
}
</style>
