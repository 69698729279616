<template>
	<Modal :modalOpened="show" @close="show = false">
		<div class="buy-ticket-modal pb-2" v-if="ticket">
			<div
				class="text-size-16 flex justify-between px-6 py-6 text-borderColor lora-bold workshop-signup-title"
			>
			<span>
				{{ ticket?.ticket?.workshop?.title }}
			</span>
				<span class="font-bold">#{{ ticket?.id }}</span>
			</div>
			<div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.ticket }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ content.groupOf }} {{ format(ticket?.ticket?.min) }}-{{
							format(ticket?.ticket?.max)
						}}
						{{ content.attendees }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.date }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ month(ticket.date) }}
						{{ day(ticket.date) }}{{ content.coma }}
						{{ year(ticket.date) }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.price }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ format(ticket?.ticket?.price) }} {{ content.iqd }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.status }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ ticket?.status }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.name }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ ticket?.name }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.email }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ ticket?.email }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.phone }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ ticket?.phone }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.address }}</div>
					<div class="pb-3 signup-workshop-content  mt-1" style="border:none;">
						{{ ticket?.address }}
					</div>
				</div>
			</div>
		</div>
	</Modal>
</template>
<script>
import { format } from '/src/assets/numberFormat';

export default {
	inject: ['content'],
	data() {
		return {
			show: false,
		};
	},
	computed: {
		ticket() {
			return this.$store.getters['workshops/guestTicket'];
		},
	},
	async mounted() {
		if (this.$route.query.id && this.$route.query.email) {
			await this.$store.dispatch('workshops/getGuestTicket', {
				uuid: this.$route.query.id,
				email: this.$route.query.email,
			});
			console.log(this.ticket);
			if (this.ticket) {
				this.show = true;
			}
		}
	},

	methods: {
		year(date) {
			return date.split(',')[0];
		},
		month(date) {
			return date.split(',')[1];
		},
		day(date) {
			return date.split(',')[2];
		},
		format(number) {
			return format(number);
		},
		close() {
			this.$emit('close');
		},
	},
};
</script>
