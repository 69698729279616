<template>
	<Modal :modalOpened="show" @close="close">
		<div class="buy-ticket-modal pb-2">
			<div
				class="text-size-16 text-center py-6 text-borderColor lora-bold workshop-signup-title"
			>
				{{ content.signUpForWorkshop }}
			</div>
			<div v-if="step === 1" class="p-3">
				<div>
					<label for="name" class="block">
						{{ content.name }}
					</label>
					<input
						type="text"
						class="form-input col-span-4 sm:col-span-3"
						v-model.trim="name"
						@change="clearError('name')"
						id="name"
						:placeholder="content.name"
					/>
					<p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
						{{ errors.name }}
					</p>
				</div>
				<div class="mt-4">
					<label for="email" class="block">
						{{ content.email }}
					</label>
					<input
						type="email"
						id="email"
						class="form-input col-span-4 sm:col-span-3"
						v-model.trim="email"
						@change="clearError('email')"
						placeholder="example@companyname.com"
					/>
					<p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
						{{ errors.email }}
					</p>
				</div>
				<div class="mt-4">
					<label for="phone" class="block">
						{{ content.phone }}
					</label>
					<input
						type="text"
						id="phone"
						class="form-input col-span-4 sm:col-span-3"
						v-model="phone"
						@change="clearError('phone')"
						placeholder="07xx xxx xxxx"
					/>
					<p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
						{{ errors.phone }}
					</p>
				</div>
				<div class="mt-4">
					<label for="address" class="block">
						{{ content.address }}
					</label>
					<textarea
						type="text"
						id="address"
						class="form-input col-span-4 sm:col-span-3"
						v-model="address"
						@change="clearError('address')"
						:placeholder="content.address"
					/>
					<p v-if="showErrors" class="error h-3 mt-2 col-span-3 col-start-2">
						{{ errors.address }}
					</p>
				</div>
				<button
					@click="nextStep"
					type="button"
					class="header-btn text-bluePrimary hover:text-white hover:bg-bluePrimary bg-blueLight w-full mt-4 duration-300"
				>
					{{ content.next }}
				</button>
			</div>
			<div v-if="step === 2">
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.workshop }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ workshop.title }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.startDate }}</div>
					<!-- <div class="pb-3 signup-workshop-content mt-1">Oct 12, 2021</div> -->
					<div class="pb-3 signup-workshop-content mt-1">
						{{ month(workshop.date) }}
						{{ day(workshop.date) }}{{ content.coma }}
						{{ year(workshop.date) }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.ticketType }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ format(ticket.min) }} - {{ format(ticket.max) }}
						{{ content.attendees }}
					</div>
				</div>
				<div class="px-6">
					<div class="pt-3 text-size-12">{{ content.price }}</div>
					<div class="pb-3 signup-workshop-content mt-1">
						{{ format(ticket.price) }} {{ content.iqd }}
					</div>
				</div>
				<div class="px-6 my-6">
					<h4 class="text-center pb-3 mb-5 signup-workshop-content">
						{{ content.chooseMethod }}
					</h4>
					<div class="flex flex-col items-center justify-center space-y-3">
						<button
							class="w-full text-white alert-btn bg-beingDelivered grid grid-cols-3 items-center"
							@click="buyTicket('cash')"
						>
							<span class="col-span-1 flex justify-start px-2">
								<i class="fas fa-money-bill-wave-alt"></i>
							</span>
							<span class="col-span-2 flex justify-start">
								{{ content.cash }}
							</span>
						</button>
						<button
							class="w-full text-white alert-btn bg-nassWallet grid grid-cols-3 items-center"
							@click="buyTicket('nasspay')"
						>
							<span class="col-span-1 flex justify-start px-2">
								<img class="h-5" src="/static/images/nw-icon.png" />
							</span>
							<span class="col-span-2 flex justify-start">
								{{ content.nasswallet }}
							</span>
						</button>
						<button
							class="w-full text-white alert-btn bg-black grid grid-cols-3 items-center"
							@click="buyTicket('zaincash')"
						>
							<span class="col-span-1 flex justify-start px-2">
								<img class="h-5" src="/static/images/zaincash-icon.png" />
							</span>
							<span class="col-span-2 flex justify-start">
								{{ content.zaincash }}
							</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</Modal>
</template>
<script>
import { format } from '/src/assets/numberFormat';
import recaptcha from '/src/assets/googleRecaptcha';

export default {
	props: ['show', 'workshop', 'ticket'],
	emits: ['close'],
	inject: ['content'],
	data() {
		return {
			step: 1,
			ticket_id: null,
			method: null,
			name: null,
			email: null,
			phone: null,
			address: null,
			token: null,
			showErrors: false,
			errors: {
				email: null,
				password: null,
				name: null,
				phone: null,
				city: null,
				dob: null,
			},
		};
	},
	mounted() {
		// console.log(recaptcha);
		recaptcha.load();
	},
	unmounted() {
		recaptcha.unload();
	},
	methods: {
		async recaptcha(method) {
			let grecaptcha = window.grecaptcha;

			grecaptcha.ready(() => {
				grecaptcha
					.execute('6LeeHJEeAAAAAHNJtMdpCWjDX6bD6rJTzJfmDkTc', {
						action: 'submit',
					})
					.then((token) => {
						this.token = token;
						this.buyTicket(method);
					});
			});
		},
		year(date) {
			return date.split(',')[0];
		},
		month(date) {
			return date.split(',')[1];
		},
		day(date) {
			return date.split(',')[2];
		},
		format(number) {
			return format(number);
		},
		close() {
			this.$emit('close');
		},
		clearError(type) {
			this.errors[type] = '';
		},
		nextStep() {
			let pass = true;
			this.errors = {
				email: null,
				name: null,
				phone: null,
				address: null,
			};
			if (!this.email) {
				this.errors.email = this.content.emailRequired;
				pass = false;
			}
			if (!this.name) {
				this.errors.name = this.content.nameRequired;
				pass = false;
			}

			if (!this.phone || this.phone == '') {
				this.errors.phone = this.content.phoneRequired;
				pass = false;
			}
			if (!this.address || this.address == '') {
				this.errors.address = this.content.addressRequired;
				pass = false;
			}
			if (!pass) {
				this.showErrors = true;
				return;
			}
			if (pass) this.step = 2;
		},
		async buyTicket(method) {
			try {
				if (!this.token) {
					await this.recaptcha(method);
				}
				await this.$store.dispatch('workshops/buyTicketGuest', {
					ticketId: this.ticket.id,
					name: this.name,
					email: this.email,
					address: this.address,
					phone: this.phone,
					method: method,
					token: this.token,
				});
				this.token = null;
				this.close();
				await this.$store.dispatch('feedback/setFeedback', {
					type: 'success',
					title: this.content.success,
					message: this.content.requestSuccess,
				});
				// this.$router.replace('/');
			} catch (error) {
				this.$store.dispatch('feedback/setFeedback', {
					type: 'fail',
					title: this.content.fail,
					message: this.content.quoteFail,
				});
				// console.log(error);
			}
			this.token = null;
		},
	},
};
</script>
